import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import emailjs from 'emailjs-com';

import spl from './splash.module.css'

function Splash({ history }) {
    useEffect(() => { history.push('/') }, [history])
    const [error, setError] = useState();
    const [success, setSuccess] = useState();

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('gts-gmail', 'template_F4bjrfVw', e.target, 'user_7kw7PU52rbkPFIjK7jJnE')
            .then(() => onEmail(true))
            .catch(() => onEmail(false));
    }

    const onEmail = success => { setSuccess(success); setError(!success) }

    const renderThankyou = <p className={spl.succ}>Thank you for your interest!<br/>We will be in touch shortly.</p>
    const renderError = <p className={spl.err}>Oops something went wrong! Please try again!</p>
    

    return (
        <>
            <article id="contact" className={spl.contact}>
                <section>
                    <p>Our website is under construction.</p>
                    <p>Any inquiries please contact<br />
                    <a href="mailto:feethings@gmail.com?subject=Hello%20FeeThings.com!">feethings@gmail.com</a> or<br />
                    <a href="tel:001508685778">(508) 685-7783</a> / <a href="tel:00330612405353">+33 06 12 40 53 53</a></p>
                </section>

                {/* <>
                    {
                        success ?
                        renderThankyou :
                        <form className={spl.form} onSubmit={sendEmail}>
                            <input type="email" name="to_email"placeholder="Your email address"/>
                            <input type="submit" value="Inquire"/>
                        </form>
                    }
                    {error && renderError}           
                </> */}
            </article>
        </>
    )
}

export default withRouter(Splash);
