import React from 'react'
import hdr from './header.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Header({ pageName }) {
    const fields = [
        {name: "Travel", icon: "globe-americas"},
        {name: "Food", icon: "cheese-swiss"},
        {name: "Wine", icon: "wine-glass-alt"},
        {name: "Events", icon: "calendar-star"},
        {name: "Hospitality", icon: "hands-heart"},
        {name: "Lifestyle", icon: "loveseat"}
    ]

    const renderFields = () => fields.map((f, i) => <span key={i} className={hdr.field}><FontAwesomeIcon icon={['fal', f.icon]} />{f.name}</span>)


    return (
        <header className={hdr.header}>

            <article>
                <h3>Travel Consulting &amp; Creative Hospitality Agency</h3>
                <section id="fields">
                    { renderFields() }
                </section>
            </article>

            <h1 className={hdr.logo}><cap>F</cap>ee<cap>T</cap>hings</h1>
            {/* <h3>{pageName}</h3> */}

            <article className={hdr.locations}>
                <section id="location">
                    Paris ⎜ Nantucket
                </section >
                <section className={hdr.socials}>
                    <a href="#"><FontAwesomeIcon icon={['fab', 'instagram']} /></a>
                    <a href="#"><FontAwesomeIcon icon={['fab', 'facebook-f']} /></a>
                    <a href="https://www.pinterest.co.uk/feethings/" target="_blank" rel="no_referrer"><FontAwesomeIcon icon={['fab', 'pinterest-p']} /></a>
                </section>
            </article>
        </header>
    )
}