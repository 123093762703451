import React from 'react';
import { Header, Footer } from './layout'
import { Splash } from './pages'
import { withRouter } from 'react-router-dom'
import './App.css';

function App({ location }) {
  const page = location.pathname.substring(1)

  return (
    <div className="App">
      <Header pageName={page}/>
          <main id={page}>
            <Splash />
            {/* <Switch location={location}>
              <Route exact path="/" component={Home} />
            </Switch> */}
            </main>
      <Footer />
    </div>
  );
}

export default withRouter(App);
